<template>
  <div id="vue-root">
    <LoadingGif class="loading-gif" v-if="loading"></LoadingGif>
    <div v-if="!loading">
      <CCardHeader>
        {{ rows.length }}
        <span v-if="rows.length === 1">Bringup</span>
        <span v-else>Bringups</span>
        this quarter
      </CCardHeader>
      <br />
      <horizontal-scroll>
        <data-table v-bind="bindings" />
      </horizontal-scroll>
      <!-- <h3 v-if="!loading && !rows.length">No Bringups for this quarter</h3> -->
    </div>
  </div>
</template>

<script>
import LoadingGif from "./LoadingGif.vue";

import DataTable from "@andresouzaabreu/vue-data-table";
// import "bootstrap/dist/css/bootstrap.min.css";
import "@andresouzaabreu/vue-data-table/dist/DataTable.css";

import ActionButton from "../components/ActionButton.vue";

import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  name: "BringupsQtr",
  components: { LoadingGif, HorizontalScroll, DataTable, ActionButton },

  mounted() {
    this.fetch_bringups_qtr();
  },
  data() {
    return {
      loading: false,
      rows: [],
    };
  },
  computed: {
    bindings() {
      return {
        columns: [
          {
            key: "file_reference",
            title: "Reference",
            sortable: true,
            sortingFunction: function(a, b) {
              return ("" + a.file_reference).localeCompare(b.file_reference);
            },
          },
          {
            title: "Actions",
            sortable: false,
            component: ActionButton,
          },
          {
            key: "pri_lawyer",
            title: "Pri User",
            sortable: true,
            sortingFunction: function(a, b) {
              return ("" + a.pri_lawyer).localeCompare(b.pri_lawyer);
            },
          },
          {
            key: "bring_up_date_pri",
            title: "Pri BU",
            // sortable: true,

            sortingFunction: function(a, b) {
              var da = new Date(a.bring_up_date_pri);
              var db = new Date(b.bring_up_date_pri);

              return da.getTime() - db.getTime();
            },
          },
          {
            key: "sec_lawyer",
            title: "Sec User",
            sortable: true,
            sortingFunction: function(a, b) {
              return ("" + a.sec_lawyer).localeCompare(b.sec_lawyer);
            },
          },
          {
            key: "bring_up_date_sec",
            title: "Sec BU",
            sortable: true,

            sortingFunction: function(a, b) {
              var da = new Date(a.bring_up_date_sec);
              var db = new Date(b.bring_up_date_sec);

              return da.getTime() - db.getTime();
            },
          },
          {
            key: "client_name",
            title: "Client",
            sortable: true,
            sortingFunction: function(a, b) {
              return ("" + a.client_name).localeCompare(b.client_name);
            },
          },
          {
            key: "category",
            title: "Category",
            sortable: true,
            sortingFunction: function(a, b) {
              return ("" + a.category).localeCompare(b.category);
            },
          },
          {
            key: "date_opened",
            title: "Date Opened",
            sortable: true,
            sortingFunction: function(a, b) {
              var da = new Date(a.date_opened);
              var db = new Date(b.date_opened);

              return da.getTime() - db.getTime();
            },
          },
          {
            key: "matter_summary",
            title: "Summary",
            sortable: false,
          },
        ],
        data: this.rows,
        showDownloadButton: false,
        sortingMode: "single",
        perPageSizes: [5, 10, 25, 50, 100],
        defaultPerPage: 5,
        /* other props...*/
      };
    },
  },
  methods: {
    fetch_bringups_qtr() {
      const axios = require("axios");

      this.loading = true;

      var vm = this;

      axios
        .get("/bringups/qtr")
        .then(function(res) {
          // handle success
          vm.rows = res.data;
          console.log(vm.rows);
          vm.loading = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          vm.loading = false;
        });
    },
  },
};
</script>

<style></style>
